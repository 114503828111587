import axios from "axios";
import React, {Suspense} from "react";
import Currency from "../section/currency.sidebar";
import Sidebar from "../component/sidebar";
import {useParams} from "react-router";

function Posts(props) {

    const {category} = useParams();

    const [pages, setPages] = React.useState(1);
    const [currentPage, setCurrentPage] = React.useState(1);

    const _c = props.category != null ? props.category : category;

    const baseURL = "https://i.benya.pro/api/c/posts?sort=desc&category=" + _c;

    // const [data, setData] = React.useState(null);
    const [posts, setPosts] = React.useState(null);

    const loadMore = (page) => {

        axios.get(baseURL + '&page=' + page).then((response) => {

            setPosts([...posts, ...response.data.data.posts])
            setCurrentPage(response.data.data.pagination.page)
        });

    }

    React.useEffect(() => {

        axios.get(baseURL).then((response) => {

            setPosts(response.data.data.posts)
            setPages(response.data.data.pagination.pages)
            setCurrentPage(response.data.data.pagination.page)
        });

    }, []);

    const _title = {
        '': "Публікації",
        'news': "Новини",
        'rumors': "#Чуткі",
        'insides': "#Інсайди",
        'opinion': "#Думка",
        'articles': 'Статті'
    };

    if (!posts) return null;

    return <div className={'container h-100 pt-5'}>

        <h1>{_title[category] ?? category}</h1>
        {/*<small className="text-secondary">*/}
        {/*    {props.category}*/}
        {/*</small>*/}

        <div className="row">

            <div className="col-md-9">

                <div className="d-flex flex-wrap">

                    {

                        posts && posts.map((post, i) =>
                            <div key={i} className="col-md-12 p-1">

                                <a href={'https://benya.pro/' + post.category?.slug + '/' + post.slug}
                                   className={'text-dark'}>

                                    <div className="border rounded-5 p-4">
                                        <small className="text-secondary">
                                            {post.data} <b className="border p-1 rounded">{post.category?.name}</b>
                                        </small>
                                        <h3>{post.title?.ua}</h3>
                                        <p>
                                            <div
                                                dangerouslySetInnerHTML={{__html: post.body?.ua.length > 100 ? post.body?.ua.substr(0, 100) + '...' : post.body?.ua}}/>

                                        </p>
                                    </div>
                                </a>

                            </div>)
                    }


                </div>

                {/*Load more*/}

                <div className="load-more text-center pt-5">

                    {
                        (posts === null || posts.length === 0) ?
                        <small className={'lead text-secondary pt-5'}>
                            Публікації ще немає
                        </small>
                        :
                        (currentPage === pages &&  posts.length > 0) ? <small className={'lead text-secondary'}>
                                Ви переглянули всі публікації

                                <a href="#top" className={'p-1'}>
                                    <small className={'text-secondary'}>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             width="16" height="16"
                                             fill="currentColor"
                                             className="bi bi-arrow-bar-up m-1"
                                             viewBox="0 0 16 16">
                                            <path fill-rule="evenodd"
                                                  d="M8 10a.5.5 0 0 0 .5-.5V3.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 3.707V9.5a.5.5 0 0 0 .5.5zm-7 2.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5z"/>
                                        </svg>
                                        На Верх</small>
                                </a>
                            </small> :
                            <div onClick={() => loadMore(currentPage + 1)}
                                 className={'btn btn-dark rounded-pill p-3 m-4 col-md-6 col-12'}>
                                Ще
                            </div>

                    }
                </div>

            </div>

            <div className="col-md-3">

                <Sidebar/>

            </div>


        </div>


    </div>
}

export default Posts;