import React, {useEffect, useMemo, useRef, useState} from "react";
import {useParams} from "react-router";
import {
    EmailShareButton,
    FacebookShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import axios from "axios";
import Helmet from "react-helmet";
import {useApi} from "../api/api_tamplate";

function DetailParseNews({props}) {

    const {slug} = useParams();

    const [showShare, setShowShare] = useState(false);

    const baseURL = "https://tokar.ua/wp-json/wp/v2/posts/" + slug.split('-')[0];

    useEffect(() => {
        // _fetch();

        const _detected = () => {
            try {
                axios.post(baseURL + 'detected').then((response) => {
                    console.log(response.data.success)
                });
            } catch (e) {
                console.log(e)
            }
        }
        _detected()
    }, []);

    const {status, data, load} = useApi(baseURL)

    let _data = JSON.parse(JSON.stringify(data))
    console.log('d ' + _data)
    let post = _data;
    // let similarPosts = _data?.similar;

    const _desc = post?.excerpt?.rendered;

    return <div className={'container h-100'}>

        <Helmet>
            <title>{post?.title?.rendered}</title>
            <meta name="description" content={"Benya Pro | Новини, Чутки, Інсайди, Думки"}/>
            <meta name="keywords"
                  content="Benya Pro | Новини, Чуткі, Інсайди, Думки"/>
            <meta name="author" content={"Bencion"}/>

            <meta key={0} property="article:tag" content={"Benya Pro"}/>
            <meta key={1} property="article:tag" content={"Новини"}/>
            <meta key={2} property="article:tag" content={"Чуткі"}/>
            <meta key={3} property="article:tag" content={"Інсайди"}/>
            <meta key={4} property="article:tag" content={"Думки"}/>

            <meta property="og:title" content={post?.title?.rendered}/>
            <meta name="og:description" content={_desc}/>
            <meta property="og:url"
                  content={"https://benya.pro/news/" + slug}/>
            <meta property="og:type" content="article"/>
            <meta property="og:image" content="https://benya.pro/b.png"/>
            <meta property="og:video" content="Your video URL"/>
            <meta property="og:audio" content="Your audio URL"/>
            <meta property="og:site_name" content="Benya Pro | Новини, Чуткі, Інсайди, Думки"/>

            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content={post?.title?.rendered}/>
            <meta name="twitter:description" content={_desc}/>
            <meta property="twitter:url"
                  content={"https://benya.pro/news/" + slug}/>
            <meta name="twitter:image" content={'https://benya.pro/b.png'}/>
            <meta name="twitter:video" content="Your video URL"/>
            <meta name="twitter:audio" content="Your audio URL"/>
        </Helmet>


        <div className={'col-md-6 col-12 pt-5 m-auto p-md-0 p-2'}>
            <div className={'sticky-top bg-white'}>

                <div className="d-flex justify-content-between align-items-center">

                    <div className="info-post">
                        <h1 className={''}>
                            {post?.title?.rendered}
                        </h1>
                    </div>
                </div>

            </div>
            <div className="d-flex justify-content-between align-items-center">
                <small className="text-secondary">
                    {post?.date?.split('T')[0].replace('-', '.').replace('-', '.')}
                </small>
            </div>
            <br/>
            <a href="https://billing.hostpro.ua/aff.php?aff=7643"><img className={'img-fluid'}
                                                                       src="https://billing.hostpro.ua/partners/uk/2021/general/728x90.png" alt="Hostpro" rel="nofollow"/></a>

            <div className="content pt-5">

                <div dangerouslySetInnerHTML={{__html: post?.content?.rendered}}/>

            </div>

            <a href={post?.link} target={'_blank'}>
                <small className="text-dark">
                    Джерело
                </small>
            </a>
        </div>

        <div className="col-md-12 row border-top mb-5 mt-3">
            <div key={1} className="col-md-4">

                <div className="rounded-5 m-auto mt-5">
                    <a href="https://www.binance.com/uk-UA/activity/referral-entry/CPA?ref=CPA_00G9803X3A">
                        <img src="/ref/b2.webp" alt="ref binance" className={'img-fluid rounded'}/>
                    </a>
                </div>
            </div>

            <div key={2} className="col-md-4">

                <div className="rounded-5 m-auto mt-5">
                    <a href="https://www.bybit.com/invite?ref=ZORXWV%230">
                        <img src="/ref/by.webp" alt="ref bybit" className={'img-fluid'}/>
                    </a>
                </div>
            </div>

            <div key={3} className="col-md-4">

                <div className="text-center rounded-5 m-auto mt-5">
                    <a href="https://billing.hostpro.ua/aff.php?aff=7643"><img  className={'img-fluid rounded-5'} src="https://billing.hostpro.ua/partners/uk/2021/general/300x600.png" alt="Hostpro" rel="nofollow"/></a>
                </div>
            </div>


        </div>

    </div>
}

export default DetailParseNews;