import React, {Suspense, useEffect} from "react";
import axios from "axios";
import {useApi} from "../api/api_tamplate";
import Clock from 'react-live-clock';
import {Helmet} from "react-helmet";
import Sidebar from "../component/sidebar";

const Currency = React.lazy(() => import("../section/currency.sidebar"));
const TelegramChannel = React.lazy(() => import("../section/tg_channel"));
const Categories = React.lazy(() => import("../section/categories_section"));
const Articles = React.lazy(() => import("../section/articles_section"));
const Posts = React.lazy(() => import("../section/posts_section"));
const TodayPosts = React.lazy(() => import("../section/today_section"));
const News = React.lazy(() => import("../section/news_section"));

function Main() {

    const clearCacheData = () => {
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });
        alert("Complete Cache Cleared");
    };

    let r = (Math.random() + 1).toString(36).substring(7);
    // const baseURL = "https://" + r + ".benya.pro/api/c/posts?p=1";

    const telegramChannels = [
        'startsomethingnow', 'ukr_businessman', 'business_ua', 'businessua'
    ];

    const baseURL = "https://i.benya.pro/api/";

    // const [dataTopNews, setData1] = React.useState(null);
    // const [dataLatestNews, setData2] = React.useState(null);
    // const [dataLatest, setData3] = React.useState(null);
    // const [dataToday, setData4] = React.useState(null);
    // const [articles, setData5] = React.useState(null);
    //
    // const [load, setLoad] = React.useState(true);

    // const _fetch = () => {
    //     try {
    //         axios.get(baseURL + 'posts?p=1').then((response) => {
    //             setData1(response.data.data.top_news);
    //             setData2(response.data.data.latest_news);//.sort((a, b) => a.id > b.id));
    //             setData3(response.data.data.latest);//.filter((a) => a.category.slug !== 'news').sort((a, b) => a.id > b.id));
    //             setData4(response.data.data.today);
    //             setData5(response.data.data.articles);
    //             setLoad(false)
    //         });
    //     } catch (e) {
    //         console.log(e)
    //     }
    // }

    useEffect(() => {
        const _detected = () => {
            try {
                axios.post(baseURL + 'detected').then((response) => {
                    console.log(response.data.success)
                });
            } catch (e) {
                console.log(e)
            }
        }
        _detected()
        // _fetch();
        // _fetchTG();
    }, []);

    const {status, data, load} = useApi(baseURL + 'c/posts?p=1')

    let _data = JSON.parse(JSON.stringify(data)).data

    let dataTopNews = _data?.top_news;
    let dataLatestNews = _data?.latest_news;
    let dataLatest = _data?.latest;
    let dataToday = _data?.today;
    let articles = _data?.articles;

    if (load) return <div className={'container p-5'}>Завантаження...</div>;

    return <div>
        {/*<div className="s" onClick={clearCacheData}>*/}
        {/*    clearCacheData*/}
        {/*</div>*/}
        {/*<Helmet>*/}
        {/*    <script async src="https://app2.weatherwidget.org/js/?id=ww_1466d09f458a5"></script>*/}
        {/*</Helmet>*/}
        <div className="container h-100">

            <div className="d-flex flex-wrap">

                <div className="col-md-3">

                    <Suspense fallback={<div>Завантаження...</div>} children={<News data={dataLatestNews}/>}/>

                </div>

                <div className="col-md-6 p-md-5 p-2 pt-0">

                    {
                        dataTopNews[0] && <>
                            <h3>Публікація Тижня</h3>
                            <div className="border rounded-5 p-3 text-dark w-100 mb-2">

                                <a href={'https://benya.pro/' + dataTopNews[0]?.category?.slug + '/' + dataTopNews[0]?.slug}>
                                    <b className={'gradient-text text-uppercase lead'}>{dataTopNews[0]?.title?.ua}</b>
                                </a>
                            </div>
                        </>
                    }

                    <a href="https://billing.hostpro.ua/aff.php?aff=7643"><img className={'img-fluid'}
                                                                               src="https://billing.hostpro.ua/partners/uk/2021/general/728x90.png" alt="Hostpro" rel="nofollow"/></a>

                    <h3 className={''}>
                        Публікації
                    </h3>

                    <div className="d-flex flex-wrap">

                        {/*First Post Today by Category*/}

                        <Suspense fallback={<div>Завантаження...</div>} children={<TodayPosts data={dataToday}/>}/>

                        {/*    /!*Telegram Channels*!/*/}

                        <div className="col-md-12 col-12 pt-5">
                            <h3>Бізнес</h3>
                        </div>

                        {
                            [...Array(4)].map((e, i) => <Suspense key={i}
                                fallback={<div>Завантаження Telegram @{telegramChannels[i]}...</div>}
                                children={<TelegramChannel channel={telegramChannels[i]} col={'col-md-12'} limit={150}/>}
                            />)
                        }

                    </div>

                    <a href="https://billing.hostpro.ua/aff.php?aff=7643"><img className={'img-fluid'}
                                                                               src="https://billing.hostpro.ua/partners/uk/2021/general/728x90.png" alt="Hostpro" rel="nofollow"/></a>

                    <Suspense fallback={<div>Завантаження...</div>} children={<Posts data={dataLatest}/>}/>

                </div>

                <div className="col-md-3">

                    <Sidebar/>

                </div>

            </div>

            {/*<a href="https://billing.hostpro.ua/aff.php?aff=7643">*/}
            {/*    <img src="https://billing.hostpro.ua/partners/uk/2021/general/250x250.png" alt=""/>*/}
            {/*    <small className="text-success">*/}
            {/*        Discount 10%: <b>benya10</b>*/}
            {/*    </small>*/}
            {/*</a>*/}

            {/*<a href="https://billing.hostpro.ua/aff.php?aff=7643"><img src="https://billing.hostpro.ua/partners/uk/2021/general/120x600.png" alt="Hostpro" rel="nofollow"/></a>*/}

            <div className="col-md-12 text-center pt-5">
                <a href="https://billing.hostpro.ua/aff.php?aff=7643"><img className={'img-fluid'}
                                                                           src="https://billing.hostpro.ua/partners/uk/2021/general/728x90.png"
                                                                           alt="Hostpro" rel="nofollow"/></a>
            </div>


            <Suspense fallback={<div>Завантаження...</div>} children={<Articles data={articles}/>}/>
            <div className="col-md-12 text-center mb-5">
                <a href="https://billing.hostpro.ua/aff.php?aff=7643"><img className={'img-fluid'}
                                                                           src="https://billing.hostpro.ua/partners/uk/2021/hosting/728x90.png"
                                                                           alt="Hostpro" rel="nofollow"/></a>
            </div>
            <Suspense fallback={<div>Завантаження...</div>} children={<Categories/>}/>
            <div className="col-md-12 text-center">
                <a href="https://billing.hostpro.ua/aff.php?aff=7643"><img className={'img-fluid'}
                                                                           src="https://billing.hostpro.ua/partners/uk/2021/vps/728x90.png"
                                                                           alt="Hostpro" rel="nofollow"/></a>
            </div>
        </div>
    </div>
}

export default Main;