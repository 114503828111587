import React, {useEffect, useState} from "react";
import axios from "axios";

function Search(props) {

    const [search, setSearch] = useState('');

    const baseURL = "https://i.benya.pro/api/c/posts?sort=desc&search=" + search;

    const [data, setData] = React.useState(null);
    const [info, setInfo] = React.useState(null);

    const _fetch = () => {
        try {
            if(search !== ''){
                axios.get(baseURL).then((response) => {
                    setData(response.data.data?.posts);
                    console.log(response.data.data)

                    if(!response.data.data?.posts.length > 0){
                        setInfo('Не знайдено')
                    }
                });
            }else{
                setInfo('Введіть запит для пошуку!')
            }
        } catch (e) {
            console.log(e)
        }
    }

    const clear = () => {
        setData()
        setSearch('')
        setInfo('')
    }

    return <div className={'container pt-5 h-100 text-center d-flex align-items-center justify-content-center'}>

        <div className={'col-md-6 col-12 '}>
            {/*<h1 className={''}>Пошук</h1>*/}

            <div className={'d-flex m-auto align-items-center'}>

                {
                    search !== '' &&
                    <button type="button" className="btn btn-dark rounded-pill p-3" onClick={clear}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                }

                <input type="text" onChange={(e) => setSearch(e.target.value)} value={search}
                       className={'form-control rounded-pill p-3 m-1'} placeholder={'Пошук...'}/>

                <button className={'btn btn-dark rounded-pill p-3 m-1'} onClick={_fetch}>
                    Знайти
                </button>

            </div>

            <div className="p-5 rounded-5 m-auto mt-5">
                <div className="d-flex flex-wrap">
                    {
                        data && data.length > 0 ?

                           data.map((post, i) =>
                                <div key={i} className="col-md-12 p-1">

                                    <a href={'https://benya.pro/' + props.category + '/' + post.slug}
                                       className={'text-dark'}>

                                        <div className="border rounded-5 p-4">
                                            <small className="text-secondary">
                                                {post.data} <b className="border p-1 rounded">{post.category.name}</b>
                                            </small>
                                            <h3>{post.title.ua}</h3>
                                            <p>
                                                <div
                                                    dangerouslySetInnerHTML={{__html: post.body.ua.length > 100 ? post.body.ua.substr(0, 100) + '...' : post.body.ua}}/>

                                            </p>
                                        </div>
                                    </a>

                                </div>)
                            : info
                    }


                </div>
            </div>
        </div>

    </div>
}

export default Search;