import React from "react";

function Footer() {

    return (
        <div className="container p-5 border-start">

            <footer>

                <div className="d-flex align-items-start justify-content-between">

                    <div className="copyright">
                        <small className="text-secondary">
                            <img src="/b2.webp" alt="logo" width={'75'} className={'border1 rounded-pill1'} />
                        </small>

                        <p className={'mt-5'}>
                           <small>
                               © {new Date().getFullYear()}. Benya Pro
                           </small>
                        </p>
                    </div>


                    <div className="copyright d-flex align-items-start">
                       <div className="p-5 pt-0 text-center d-none d-md-block">
                           <img src="/qr.webp" alt="usdt trc20" className={'rounded'} width={'150'}/>
                            <br/>
                           <small className="text-secondary" style={{
                               fontSize: '10px'
                           }}>
                               <i>USDT (TRC20)</i>
                           </small>
                       </div>
                       <div>
                           <a href="/info" target={'blank'}>
                               <small className="text-secondary">
                                   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        className="bi bi-info" viewBox="0 0 16 16">
                                       <path
                                           d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                   </svg> Info
                               </small>
                           </a>
                           <p className={'mt-5 pt-5'}>

                               <a href="#top" className={'p-1'}>
                                   <small className={'text-secondary'}> <svg xmlns="http://www.w3.org/2000/svg"
                                                                             width="16" height="16"
                                                                             fill="currentColor"
                                                                             className="bi bi-arrow-bar-up m-1"
                                                                             viewBox="0 0 16 16">
                                       <path fill-rule="evenodd"
                                             d="M8 10a.5.5 0 0 0 .5-.5V3.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 3.707V9.5a.5.5 0 0 0 .5.5zm-7 2.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5z"/>
                                   </svg>На Верх</small>
                               </a>
                           </p>
                       </div>
                    </div>

                </div>

            </footer>
        </div>
    );
}

export default Footer;
