import React, {Suspense} from "react";
import Currency from "../section/currency.sidebar";
import Clock from "react-live-clock";

const TelegramChannel = React.lazy(() => import("../section/tg_channel"));

function Sidebar() {

    const telegramChannels = [
        'borodatayaba', 'legitimniy', 'rezident_ua', 'dark_k', 'tayni_deputata'
    ];

    return (
        <>
            {/*<div className="row p-2 pt-5">*/}
            {/*    <div className="col-md-6 col-6">*/}
            {/*        <h3>*/}
            {/*            <Clock format="HH:mm:ss" interval={1000} ticking={true}/>*/}
            {/*        </h3>*/}

            {/*    </div>*/}
            {/*    <div className="col-md-6 col-6">*/}
            {/*        <h5 className="text-secondary">*/}
            {/*            {*/}
            {/*                new Date().toLocaleDateString('en-GB').replace('/', '.').replace('/', '.')*/}
            {/*            }*/}
            {/*        </h5>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {
                window.location.pathname === '/' && <>


                    <Suspense fallback={<div>Завантаження...</div>}>
                        <Currency/>
                    </Suspense>
                    <a href=" https://accounts.binance.com/register?ref=78325564">
                        <img src="/ref/b2.webp" alt="ref binance" className={'img-fluid rounded mb-5'}/>
                    </a>
                </>
            }

            <a href="https://www.bybit.com/invite?ref=ZORXWV%230">
                <img src="/ref/by.webp" alt="ref bybit" className={'img-fluid'}/>
            </a>

            <div className={'text-center pt-5'}>

                <img src="/qr.webp" alt="usdt trc20" className={'rounded border'} width={'200'}/>
                <br/>
                <small className="text-secondary" style={{
                    fontSize: '10px'
                }}>
                    <i>USDT (TRC20)</i>
                </small>
            </div>
            {
                window.location.pathname === '/' && <>

                    <div className="col-md-12 col-12 pt-5">
                        <h3>Політика</h3>
                    </div>

                    {
                        [...Array(5)].map((e, i) => <Suspense key={i}
                                                              fallback={<div>Завантаження Telegram
                                                                  @{telegramChannels[i]}...</div>}
                                                              children={<TelegramChannel channel={telegramChannels[i]}
                                                                                         col={'col-md-12'}
                                                                                         limit={150}/>}
                        />)
                    }

                </>
            }

            <div className="col-md-12 text-center mt-3">
                <a href="https://billing.hostpro.ua/aff.php?aff=7643"><img className={'img-fluid'}
                                                                           src="https://billing.hostpro.ua/partners/uk/2021/general/300x600.png"
                                                                           alt="Hostpro" rel="nofollow"/></a>
            </div>
        </>
    );
}

export default Sidebar;
