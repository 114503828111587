import React, {Suspense, useEffect, useRef, useState} from "react";
import {useParams} from "react-router";
import {
    EmailShareButton,
    FacebookShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import axios from "axios";
import Helmet from "react-helmet";
import {useApi} from "../api/api_tamplate";

const TelegramChannel = React.lazy(() => import("../section/tg_channel"));

function TelegramPost(props) {

    const {channel, post_id} = useParams();

    const url = 'https://t.me/' + channel + '/' + post_id;

    const _url = 'tg/' + channel + '/' + post_id;

    const [showShare, setShowShare] = useState(false);

    const telegramChannels = [
        {
            "name": "Бородатая Бабушка",
            "tg": "borodatayaba"
        },
        {
            "name": "Легитимный",
            "tg": "legitimniy"
        },
        {
            "name": "Резидент",
            "tg": "rezident_ua"
        },
        {
            "name": "Тёмный Рыцарь",
            "tg": "dark_k"
        },
        {
            "name": "ТАЙНЫ ДЕПУТАТА",
            "tg": "tayni_deputata"
        },
    ];

    const _title = telegramChannels.map((tg, i) => tg.tg === channel && tg.name);

    return <div className={'container h-100'}>

        <Helmet>
            <title>{props.title}</title>
            <meta name="description" content={"Benya Pro | Новини, Чутки, Інсайди, Думки"}/>
            <meta name="keywords"
                  content="Benya Pro | Новини, Чуткі, Інсайди, Думки"/>
            <meta name="author" content={"Bencion"}/>

            <meta key={0} property="article:tag" content={"Benya Pro"}/>
            <meta key={1} property="article:tag" content={"Новини"}/>
            <meta key={2} property="article:tag" content={"Чуткі"}/>
            <meta key={3} property="article:tag" content={"Інсайди"}/>
            <meta key={4} property="article:tag" content={"Думки"}/>

            <meta property="og:title" content={channel}/>
            {/*<meta name="og:description" content={props.desc}/>*/}
            <meta property="og:url" content={"https://benya.pro/" + _url}/>
            <meta property="og:type" content="article"/>
            <meta property="og:image" content="https://benya.pro/b.png"/>
            <meta property="og:video" content="Your video URL"/>
            <meta property="og:audio" content="Your audio URL"/>
            <meta property="og:site_name" content="Benya Pro | Новини, Чуткі, Інсайди, Думки"/>

            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content={channel} />
            {/*<meta name="twitter:description"  content={_desc}/>*/}
            <meta property="twitter:url" content={"https://benya.pro/" + _url}/>
            <meta name="twitter:image" content={'https://benya.pro/b.png'}/>
            <meta name="twitter:video" content="Your video URL"/>
            <meta name="twitter:audio" content="Your audio URL"/>
        </Helmet>

        <div className={'col-md-6 col-12 pt-5 m-auto p-md-0 p-2'}>
            <div className={'sticky-top bg-white'}>

                <div className="d-flex justify-content-between align-items-center">

                    <div className="info-post">
                        <small className="text-secondary">
                            @{channel}
                        </small>

                        <h1 className={''}>
                            {_title}
                        </h1>
                    </div>

                    <div className="share d-flex">

                        {
                            !showShare &&
                            <div className={"share-btn"}
                                 onClick={() => setShowShare(!showShare)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                                     className="bi bi-share" viewBox="0 0 16 16">
                                    <path
                                        d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"/>
                                </svg>
                            </div>
                        }
                        {
                            showShare && <div id={'tg_share_menu'} className={showShare ? 'fade d-flex' : ''}>
                                <FacebookShareButton
                                    url={url}
                                    quote={''}
                                    hashtag={"#" + channel}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor"
                                         className="bi bi-facebook m-1" viewBox="0 0 16 16">
                                        <path
                                            d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                                    </svg>
                                </FacebookShareButton>

                                <TwitterShareButton
                                    url={url}
                                    quote={''}
                                    hashtag={"#" + channel}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor"
                                         className="bi bi-twitter m-1" viewBox="0 0 16 16">
                                        <path
                                            d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                                    </svg>
                                </TwitterShareButton>

                                <TelegramShareButton
                                    url={url}
                                    quote={''}
                                    hashtag={"#" + channel}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor"
                                         className="bi bi-telegram m-1" viewBox="0 0 16 16">
                                        <path
                                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/>
                                    </svg>
                                </TelegramShareButton>

                                <WhatsappShareButton
                                    url={url}
                                    quote={''}
                                    hashtag={"#" + channel}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor"
                                         className="bi bi-whatsapp m-1" viewBox="0 0 16 16">
                                        <path
                                            d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
                                    </svg>
                                </WhatsappShareButton>

                                <EmailShareButton
                                    url={url}
                                    quote={''}
                                    hashtag={"#" + channel}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor"
                                         className="bi bi-envelope m-1" viewBox="0 0 16 16">
                                        <path
                                            d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                                    </svg>
                                </EmailShareButton>

                                <div className="p-1" onClick={() => {
                                    document.getElementById('tg_share_menu').classList.remove('fade')
                                    document.getElementById('tg_share_menu').classList.add('fadeout')
                                    setTimeout(() => setShowShare(!showShare),  2400)
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-x-lg" viewBox="0 0 16 16">
                                        <path
                                            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                                    </svg>
                                </div>
                            </div>
                        }


                    </div>

                </div>

            </div>
            <div className="content pt-5">

                <iframe
                    src={url + '?embed=1'}
                    id={'telegram-post' + post_id}
                    style={{
                        minHeight: '80vh',
                        maxHeight: 'auto',
                        width: '100%'
                    }}
                />

            </div>



        </div>
        <h3>Схожі пости</h3>
        <div className="d-flex flex-wrap mb-5">

            {
                telegramChannels.map((tg, i) => {
                   return tg.tg !== channel &&  <Suspense fallback={<div>Завантаження Telegram...</div>}>
                        <TelegramChannel channel={telegramChannels[i].tg} col={'col-md-3'} limit={500}/>
                    </Suspense>
                })
            }

        </div>

    </div>
}

export default TelegramPost;