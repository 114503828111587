import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NoPage from "./pages/404";
import Main from "./pages/main";
import Info from "./pages/info";
import Posts from "./pages/posts";
import Search from "./pages/search";
import Post from "./pages/post";
import TelegramPost from "./pages/telegram_post";
import CryptoPage from "./pages/crypto";
import InfoDetail from "./pages/info_detail";
import DetailParseNews from "./pages/detail_parse_news";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<App />}>
                  <Route index element={<Main />} />

                  <Route path="posts" element={<Posts category={''} />} />
                  <Route path="/:category" element={<Posts category={null} />} />

                  <Route path="/news/:slug" element={<DetailParseNews />} />

                  <Route path="/:category/:slug" element={<Post />} />
                  <Route path="/:category/:subcategory/:slug" element={<Post />} />

                  <Route path="/tg/:channel/:post_id" element={<TelegramPost />} />

                  <Route path="search" element={<Search />} />

                  <Route path="info" element={<Info />} />
                  <Route path="i/:type" element={<InfoDetail />} />

                  <Route path="support" element={<CryptoPage />} />

                  <Route path="*" element={<NoPage />} />
              </Route>
          </Routes>
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
