
import { Outlet, Link } from "react-router-dom";
import Helmet from "react-helmet";
import React, {useState} from "react";

function Header() {

    const [isMobileMenu, setIsMobileMenu] = useState(false);

    const showMobileMenu = () => {

        setIsMobileMenu(!isMobileMenu)

        if(isMobileMenu){
            document.getElementById('navbarNav').classList.remove('collapse')
        }else{
            document.getElementById('navbarNav').classList.add('collapse')
        }
    }


    return (
        <div id={'top'} className="header">

            <Helmet>
                <script src="https://www.cryptohopper.com/widgets/js/script"></script>
            </Helmet>

            <header className="container p-md-3 p-2 border-bottom">


                <nav className="navbar navbar-expand-lg navbar-light bg- p-3">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="/">
                            <img src="/b2.webp" alt="logo" width={'45'} className={'border1 rounded-pill1'} />
                        </a>
                        <button className="btn btn-link d-md-none"
                            onClick={showMobileMenu}
                        >
                            <span className="navbar-toggler-icon"/>
                        </button>

                        <div className="collapse navbar-collapse text-center" id="navbarNav">
                            <ul className="navbar-nav1 ms-auto d-flex flex-wrap list-unstyled justify-content-between align-items-center mt-4">
                                {/*<li className="nav-item">*/}
                                {/*    <Link to="/news" className="nav-link mx-2 active" aria-current="page"  >Новини</Link>*/}
                                {/*</li>*/}
                                <li className="nav-item">
                                    <a className="nav-link mx-2  text-secondary" href="/rumors">#Чуткі</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link mx-2 text-secondary" href="/insides">#Інсайди</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link mx-2 text-secondary" href="/opinion">#Думка</a>
                                </li>

                                <li className="nav-item d-md-none">
                                    <a className="nav-link mx-2" href="/articles">Статті</a>
                                </li>
                                <li className="nav-item d-md-none">
                                    <a className="nav-link mx-2" href="/posts">Публікації</a>
                                </li>

                                <li className="nav-item d-md-none">
                                    <a className="nav-link mx-2" href="/search">
                                        <a className="nav-link text-dark h5" href="/search" target="blank">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                 fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                                <path
                                                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                            </svg>
                                        </a>
                                    </a>
                                </li>

                                {/*<li className="nav-item">*/}
                                {/*    <a className="nav-link text-dark h5" href="https://t.me/s/-" target="blank">*/}
                                {/*        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"*/}
                                {/*             fill="currentColor" className="bi bi-telegram" viewBox="0 0 16 16">*/}
                                {/*            <path*/}
                                {/*                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/>*/}
                                {/*        </svg>*/}
                                {/*    </a>*/}
                                {/*</li>*/}
                            </ul>
                            <ul className="navbar-nav ms-auto d-none d-lg-inline-flex">
                                <li className="nav-item mx-2">
                                    <a className="nav-link text-dark h5" href="/search" target="blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                            <path
                                                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                        </svg>
                                    </a>
                                </li>
                                <li className="nav-item mx-2">
                                    <a className="nav-link text-dark h5" href="https://t.me/s/benyapro" target="blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-telegram" viewBox="0 0 16 16">
                                            <path
                                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/>
                                        </svg>
                                    </a>
                                </li>
                                <li className="nav-item mx-2">
                                    <a className="nav-link text-dark h5" href="/support" target="blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-heart-fill" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd"
                                                  d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>

            </header>
        </div>
    );
}

export default Header;
