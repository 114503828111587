import React, {useEffect, useMemo, useRef, useState} from "react";
import {useParams} from "react-router";
import {
    EmailShareButton,
    FacebookShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import axios from "axios";
import Helmet from "react-helmet";
import {useApi} from "../api/api_tamplate";

function Post() {

    const {category, subcategory, slug} = useParams();

    const [showShare, setShowShare] = useState(false);


    const baseURL = "https://i.benya.pro/api/c/post/" + slug;

    // const [data, setData] = React.useState(null);
    // const [data2, setData2] = React.useState(null);

    // const _fetch = () => {
    //     try{
    //         axios.get(baseURL).then((response) => {
    //             setpost(response.data.data.post);
    //             setpost2(response.data.data.similar);
    //             console.log(response.data.data)
    //         });
    //     }catch (e){
    //         console.log(e)
    //     }
    // }

    useEffect(() => {
        // _fetch();

        const _detected = () => {
            try {
                axios.post(baseURL + 'detected').then((response) => {
                    console.log(response.data.success)
                });
            } catch (e) {
                console.log(e)
            }
        }
        _detected()
    }, []);

    const {status, data, load} = useApi(baseURL)

    let _data = JSON.parse(JSON.stringify(data)).data
    let post = _data?.post;
    let similarPosts = _data?.similar;

    const _desc = (post?.body.ua.length > 100 ? post?.body.ua.substr(0, 100) + '...' : post?.body.ua)?.replace('<p>', '');

    return <div className={'container h-100'}>

        <Helmet>
            <title>{post?.title.ua}</title>
            <meta name="description" content={"Benya Pro | Новини, Чутки, Інсайди, Думки"}/>
            <meta name="keywords"
                  content="Benya Pro | Новини, Чуткі, Інсайди, Думки"/>
            <meta name="author" content={"Bencion"}/>

            <meta key={0} property="article:tag" content={"Benya Pro"}/>
            <meta key={1} property="article:tag" content={"Новини"}/>
            <meta key={2} property="article:tag" content={"Чуткі"}/>
            <meta key={3} property="article:tag" content={"Інсайди"}/>
            <meta key={4} property="article:tag" content={"Думки"}/>

            <meta property="og:title" content={post?.title.ua}/>
            <meta name="og:description" content={_desc}/>
            <meta property="og:url"
                  content={"https://benya.pro/" + post?.category.slug + "/" + post?.slug}/>
            <meta property="og:type" content="article"/>
            <meta property="og:image" content="https://benya.pro/b.png"/>
            <meta property="og:video" content="Your video URL"/>
            <meta property="og:audio" content="Your audio URL"/>
            <meta property="og:site_name" content="Benya Pro | Новини, Чуткі, Інсайди, Думки"/>

            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content={post?.title.ua}/>
            <meta name="twitter:description" content={_desc}/>
            <meta property="twitter:url"
                  content={"https://benya.pro/" + post?.category.slug + "/" + post?.slug}/>
            <meta name="twitter:image" content={'https://benya.pro/b.png'}/>
            <meta name="twitter:video" content="Your video URL"/>
            <meta name="twitter:audio" content="Your audio URL"/>
        </Helmet>


        <div className={'col-md-6 col-12 pt-5 m-auto p-md-0 p-2'}>
            <div className={'sticky-top bg-white'}>

                <div className="d-flex justify-content-between align-items-center">

                    <div className="info-post">
                        <small className="text-secondary">
                            {post?.category.name}
                        </small>

                        <h1 className={''}>
                            {post?.title.ua}
                        </h1>
                    </div>

                    {/*<div className="share d-flex contentMenu1">*/}
                    {/*    {*/}
                    {/*        !showShare &&*/}

                    {/*        <div className={"share-btn"}*/}
                    {/*            onClick={() => setShowShare(!showShare)}>*/}
                    {/*            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"*/}
                    {/*                 className="bi bi-share" viewBox="0 0 16 16">*/}
                    {/*                <path*/}
                    {/*                    d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"/>*/}
                    {/*            </svg>*/}
                    {/*        </div>*/}
                    {/*    }*/}
                    {/*    {*/}
                    {/*        showShare &&*/}
                    {/*        <div className={showShare ? 'fade d-flex' : ''}>*/}
                    {/*            <FacebookShareButton*/}
                    {/*                url={post.share.facebook}*/}
                    {/*                quote={post.title.ua}*/}
                    {/*                hashtag={"#" + category}*/}
                    {/*            >*/}
                    {/*                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor"*/}
                    {/*                     className="bi bi-facebook m-1" viewBox="0 0 16 16">*/}
                    {/*                    <path*/}
                    {/*                        d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>*/}
                    {/*                </svg>*/}
                    {/*            </FacebookShareButton>*/}

                    {/*            <TwitterShareButton*/}
                    {/*                url={post.share.twitter}*/}
                    {/*                quote={post.title.ua}*/}
                    {/*                hashtag={"#" + category}*/}
                    {/*            >*/}
                    {/*                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor"*/}
                    {/*                     className="bi bi-twitter m-1" viewBox="0 0 16 16">*/}
                    {/*                    <path*/}
                    {/*                        d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>*/}
                    {/*                </svg>*/}
                    {/*            </TwitterShareButton>*/}

                    {/*            <TelegramShareButton*/}
                    {/*                url={post.share.telegram}*/}
                    {/*                quote={post.title.ua}*/}
                    {/*                hashtag={"#" + category}*/}
                    {/*            >*/}
                    {/*                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor"*/}
                    {/*                     className="bi bi-telegram m-1" viewBox="0 0 16 16">*/}
                    {/*                    <path*/}
                    {/*                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/>*/}
                    {/*                </svg>*/}
                    {/*            </TelegramShareButton>*/}

                    {/*            <WhatsappShareButton*/}
                    {/*                url={post.share.whatsapp}*/}
                    {/*                quote={post.title.ua}*/}
                    {/*                hashtag={"#" + category}*/}
                    {/*            >*/}
                    {/*              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor"*/}
                    {/*                     className="bi bi-whatsapp m-1" viewBox="0 0 16 16">*/}
                    {/*                    <path*/}
                    {/*                        d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>*/}
                    {/*                </svg>*/}
                    {/*            </WhatsappShareButton>*/}

                    {/*            <EmailShareButton*/}
                    {/*                url={post.share.email}*/}
                    {/*                quote={post.title.ua}*/}
                    {/*                hashtag={"#" + category}*/}
                    {/*            >*/}
                    {/*                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor"*/}
                    {/*                     className="bi bi-envelope m-1" viewBox="0 0 16 16">*/}
                    {/*                    <path*/}
                    {/*                        d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>*/}
                    {/*                </svg>*/}
                    {/*            </EmailShareButton>*/}

                    {/*            <div className="p-1" onClick={() => setShowShare(!showShare)}>*/}
                    {/*                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"*/}
                    {/*                     className="bi bi-x-lg" viewBox="0 0 16 16">*/}
                    {/*                    <path*/}
                    {/*                        d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>*/}
                    {/*                </svg>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    }*/}


                    {/*</div>*/}

                </div>

            </div>
            <div className="d-flex justify-content-between align-items-center">
                <small className="text-secondary">
                    {post?.post}
                </small>
                <div className="share d-flex">
                    {
                        !showShare &&

                        <div className={"share-btn"}
                             onClick={() => setShowShare(!showShare)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor"
                                 className="bi bi-share" viewBox="0 0 16 16">
                                <path
                                    d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"/>
                            </svg>
                        </div>
                    }
                    {
                        showShare &&
                        <div id={'share_menu'} className={showShare ? 'fade d-flex' : ''}>
                            <FacebookShareButton
                                url={post.share.facebook}
                                quote={post.title.ua}
                                hashtag={"#" + category}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor"
                                     className="bi bi-facebook m-1" viewBox="0 0 16 16">
                                    <path
                                        d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                                </svg>
                            </FacebookShareButton>

                            <TwitterShareButton
                                url={post.share.twitter}
                                quote={post.title.ua}
                                hashtag={"#" + category}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor"
                                     className="bi bi-twitter m-1" viewBox="0 0 16 16">
                                    <path
                                        d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                                </svg>
                            </TwitterShareButton>

                            <TelegramShareButton
                                url={post.share.telegram}
                                quote={post.title.ua}
                                hashtag={"#" + category}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor"
                                     className="bi bi-telegram m-1" viewBox="0 0 16 16">
                                    <path
                                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/>
                                </svg>
                            </TelegramShareButton>

                            <WhatsappShareButton
                                url={post.share.whatsapp}
                                quote={post.title.ua}
                                hashtag={"#" + category}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor"
                                     className="bi bi-whatsapp m-1" viewBox="0 0 16 16">
                                    <path
                                        d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
                                </svg>
                            </WhatsappShareButton>

                            <EmailShareButton
                                url={post.share.email}
                                quote={post.title.ua}
                                hashtag={"#" + category}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor"
                                     className="bi bi-envelope m-1" viewBox="0 0 16 16">
                                    <path
                                        d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                                </svg>
                            </EmailShareButton>

                            <div className="p-1" onClick={() => {
                                document.getElementById('share_menu').classList.remove('fade')
                                document.getElementById('share_menu').classList.add('fadeout')
                                setTimeout(() => setShowShare(!showShare), 2400)
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-x-lg" viewBox="0 0 16 16">
                                    <path
                                        d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                                </svg>
                            </div>
                        </div>
                    }


                </div>

            </div>
            <br/>
            <a href="https://billing.hostpro.ua/aff.php?aff=7643"><img className={'img-fluid'}
                                                                       src="https://billing.hostpro.ua/partners/uk/2021/general/728x90.png" alt="Hostpro" rel="nofollow"/></a>

            <div className="content pt-5">

                <div dangerouslySetInnerHTML={{__html: post?.body.ua}}/>

            </div>
        </div>

        <h3>Пов'язані публікації</h3>
        <div className="col-md-12 row border-top mb-5">
            <div key={-1} className="col-md-4">

                <div className="border rounded-5 m-auto mt-5">
                    <a href="https://www.binance.com/uk-UA/activity/referral-entry/CPA?ref=CPA_00G9803X3A">
                        <img src="/ref/b1.webp" alt="ref binance" className={'img-fluid rounded'}/>
                    </a>
                </div>
            </div>
            {
                similarPosts && similarPosts.length > 0 && similarPosts.map((item, i) => <div key={i}
                                                                                              className="col-md-4">

                    <div className="border p-5 rounded-5 m-auto mt-5">

                        <small className="text-secondary">
                            {item.post} <b className="border p-1 rounded">{item.category.name}</b>
                        </small>
                        <a href={'https://benya.pro/' + item.category.slug + '/' + item.slug} className={'text-dark'}>
                            <h4>{
                                item.title.ua
                            }</h4>
                            <p>
                                <div
                                    dangerouslySetInnerHTML={{__html: item.body.ua.length > 100 ? item.body.ua.substr(0, 100) + '...' : item.body.ua}}/>
                            </p>
                        </a>

                    </div>

                </div>)
            }

        </div>

    </div>
}

export default Post;