import axios from "axios";
import React from "react";

const baseURL = "https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?json";

export default function Currency() {
    const [data, setData] = React.useState(null);

    React.useEffect(() => {
        axios.get(baseURL).then((response) => {
            setData(response.data);
            console.log(response.data)
        });
    }, []);

    if (!data) return null;

    return (
        <div>
            <div className="p-2 pb-0 d-none d-md-block">
                <h5 className={'pb-0'}>Курс валют</h5>

                <div className="row">

                    <div className="col-md-4 col-6">
                        {/*<small className="text-secondary">*/}
                        {/*    {*/}
                        {/*        new Date().toLocaleDateString('en-GB').replace('/', '.').replace('/', '.')*/}
                        {/*    }*/}
                        {/*</small>*/}
                        <div className="d-flex flex-wrap mt-2">
                            {
                                data.map((item, i) => <div key={i} className="">

                                    {
                                        ['USD', 'EUR', 'GBP'].includes(item.cc) && <div className={'p-1'}>
                            <small>
                                {item.rate}
                            </small> <br/>
                                            <small className="text-secondary">
                                                {item.cc}
                                            </small>
                                        </div>
                                    }

                                </div>)
                            }
                        </div>

                    </div>

                    <div className="col-md-8 col-6 d-none d-md-block">
                        <img src="/sticker1.webp"
                             alt="tg" className={'position-absolute1 rounded-5 img-fluid'} width={'200'}/>
                    </div>

                </div>

            </div>

        </div>
    );
}