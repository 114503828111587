import {useEffect} from "react";
import axios from "axios";

function NoPage(){

    return <div className={'container vh-100 d-flex align-items-center justify-content-center'}>

            <div className={'border rounded-5 p-5 col-md-6 col-12 shadow'}>

                <h1>Помилка</h1>
                <small className="text-secondary">
                    Error code: 404
                </small>
                <p>
                    Сторінка не знайдена!
                </p>
                <a href="/" className={'btn btn-dark rounded-pill p-3'}>
                    На Головну
                </a>

            </div>

    </div>
}

export default NoPage;