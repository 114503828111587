import {useEffect, useState} from 'react'

const cache = {};

export const useApi = (url) => {

    const [status, setStatus] = useState('idle');
    const [data, setData] = useState([]);
    const [load, setLoad] = useState(true);

    useEffect(() => {
        if (!url) return;

        const fetchData = async () => {
            setStatus('fetching');
            if (cache[url]) {
                const data = cache[url];
                setData(data);
                setStatus('fetched');
            } else {
                const response = await fetch(url);
                const data = await response.json();
                cache[url] = data; // set response in cache;
                setData(data);
                setStatus('fetched');
            }
            setLoad(false)
        };

        fetchData();
    }, [url]);

    return {status, data, load};
};