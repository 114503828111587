import React, {useEffect, useState} from "react";
import axios from "axios";
import {useParams} from "react-router";

const _policy = '/file/privacy-policy.txt';
const _terms = '/file/term-of-use.txt';

function InfoDetail(){

    const {type} = useParams();

    const _path = {
        'privacy-policy': {
            'title' : "Політика конфіденційності",
            'file' : _policy
        },
        'term-of-use': {
            'title' : "Правила користування",
            'file': _terms
        }
    };

    const [content, setContent] = useState();
    const [load, setLoad] = useState(true);

    const _file = (link) => {
        fetch(link)
            .then(response => response.text())
            .then(text =>{
                console.log('text decoded:', text);
                setLoad(false)
                setContent(<div dangerouslySetInnerHTML={{__html: text}} />)
            });
    }

    useEffect(() => {
        _file(_path[type].file)
    }, [content])

    if(load) return <div>Завантаження...</div>

    return <div className={'container p-5 h-100 d-flex flex-wrap align-items-center justify-content-around'}>

        <div className={'border rounded-5 p-5 col-md-8 col-12 shadow'}>

            <h1>{_path[type].title ?? '-'}</h1>

            <p className={''} style={{textAlign: 'justify'}}>
                {content}
            </p>

        </div>

    </div>
}

export default InfoDetail;