import Header from "./component/header";
import Footer from "./component/footer";
import {Outlet, Link, useSearchParams} from "react-router-dom";
import {useEffect} from "react";
import axios from "axios";


function App({props}) {

  useEffect(() => {
      axios.post('https://i.benya.pro/api/detected').then((response) => {
          console.log(response.data.data)
      });
  }, []);

  return (
    <div className="App">
        <Header />
        <div className="cryptohopper-web-widget" data-id="2" />
        <main>

            <Outlet />

        </main>

        {/*<div className="cryptohopper-web-widget" data-id="2" />*/}

        <Footer/>
    </div>
  );
}

export default App;
