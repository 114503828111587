import {useEffect} from "react";
import axios from "axios";

function Info(){

    return <div className={'container p-5 h-100 d-flex flex-wrap align-items-center justify-content-around'}>

        <div className={'border rounded-5 p-5 col-md-6 col-12 shadow'}>

            <h1>Інформація</h1>
            <small className="text-secondary">
                Benya Pro
            </small>
            <p className={'lead'}>
                Медіа ресурс <b>
                <a href="https://benya.pro" className={'gradient-text text-uppercase'}>benya.pro</a>
            </b> є не комерційним проектом. Всі матеріали, інформація та медіа представлення (відео, зображення, аудіо), що представлені на сайті дозволяються до цитування, копіювання та будь якого іншого відтворення з вказанням на джерело.
            </p>


           <div className="d-flex">
               <div className={'p-1'}>
                   <small className="text-secondary">
                       Контакти
                   </small>
                   <p>
                       <a href="mailto:bencion@proton.me" target={'_blank'} >
                           <small className="text-secondary">
                               i@benya.pro
                           </small>
                       </a>
                   </p>
               </div>

               {/*<div className={'p-1'}>*/}
               {/*    <small className="text-secondary">*/}
               {/*        Підтримати*/}
               {/*    </small>*/}
               {/*    <p>*/}
               {/*        <a href="/support" target={'_blank'}>*/}
               {/*            <small className="text-secondary">*/}
               {/*               Донат*/}
               {/*            </small>*/}
               {/*        </a>*/}
               {/*    </p>*/}
               {/*</div>*/}
           </div>

        </div>
       <div className={'col-md-4 col-12 text-start pt-2'}>

           <div className="p-5 rounded-5 shadow">
               <h2>Дисклеймер</h2>
               <p className={'lead gradient-text text-uppercase'}>
                   <b>
                       Будь-який збіг з реальними людьми або подіями, є випадковістю!
                   </b>
               </p>
           </div>
           <br/>
           <a href="/i/term-of-use" className={'btn btn-dark rounded-pill p-3 m-1'}>
               Правила користування
           </a>
            <br/>
           <a href="/i/privacy-policy" className={'btn btn-dark rounded-pill p-3 m-1'}>
               Політика конфіденційності
           </a>
       </div>
    </div>
}

export default Info;