import React, {useEffect} from "react";
import axios from "axios";

function CryptoPage(){

    return <div className={'container vh-100 d-flex align-items-center justify-content-center'}>

        <div className={'border rounded-5 p-5 col-md-6 col-12 shadow'}>

            <h1>Підтримка</h1>

            <div className="d-flex flex-wrap align-items-start justify-content-between">
                <div>
                    <small className="text-secondary">
                        USDT (TRC20)
                    </small>
                    <p>
                        TVUAA7f5kjnVJtauKRb6GxW6DaWqkW8fbY
                    </p>
                </div>
                <div className="pt-0">
                    <img src="/qr.webp" alt="usdt trc20" className={'rounded border p-2 mb-1'} width={'200'}/>
                </div>
            </div>
            <a href="/" className={'btn btn-dark rounded-pill p-3 col-md-4 col-12'}>
                На Головну
            </a>

        </div>

    </div>
}

export default CryptoPage;